<template>
  <div class="login">
    <div class="_container">
      <div class="login__content">
        <div class="login__main-content">
          <div class="logo">
            <img src="@/assets/img/header/logo.svg" alt="logo" />
          </div>
          <div class="subtitle">
            <div class="subtitle__text">
              {{ loginPageFields.title.text }}
            </div>
            <div class="subtitle__text">
              {{ loginPageFields.title2.text }}
            </div>
          </div>
        </div>
        <div class="login__second-content" @keyup.enter="onSignIn">
          <InputFields v-model="form.email" default :placeholder="loginPageFields.inputPlaceholder1.text" />
          <div class="password">
            <InputFields
              v-model="form.password"
              default
              :placeholder="loginPageFields.inputPlaceholder2.text"
              :type="passwordType"
            />
            <img
              v-if="passwordType == 'text'"
              src="@/assets/img/svg/password-show.svg"
              alt="show-password"
              @click="changePasswordType"
            />
            <img
              v-if="passwordType == 'password'"
              src="@/assets/img/svg/password-hide.svg"
              alt="show-password"
              @click="changePasswordType"
            />
          </div>
          <nuxt-link :to="localePath(`/reset-password`)" class="forgot-password" tag="div">
            {{ loginPageFields.forgotYourPassword.text }}
          </nuxt-link>
          <div class="sign-in" @click="onSignIn">
            <Btn base :name="loginPageFields.btn.text" />
          </div>
          <div class="sign-up">
            {{ loginPageFields.lowerPage1.text }}
            <nuxt-link id="LSingUpLink" :to="localePath(`/registration`)" class="link">
              {{ loginPageFields.lowerPage2.text }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <SendEmailCode
      v-model="isShowSendEmailCode"
      :email="form.email"
      :loading="isSendingEmailCode"
      @sendEmailCode="sendEmailCode"
      :translation="loginPageFields"
    />
    <ConfirmEmailCode
      v-model="isShowConfirmEmailCode"
      :loading="isConfirmingEmailCode"
      :error-confirm-code="errorConfirmCode"
      @sendEmailCode="sendEmailCode"
      @confirmEmailCode="verifyEmailCode"
      @resetErrorConfirmCode="resetErrorConfirmCode"
      :translation="loginPageFields"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import notificationService from '@/services/notification';
import Btn from '@/components/controls/Btn.vue';
import InputFields from '@/components/controls/Input-fields.vue';
import SendEmailCode from '@/components/pop-up/account/sendEmailCode.vue';
import ConfirmEmailCode from '@/components/pop-up/account/ConfirmEmailCode.vue';
import { emailValidations } from '@/utils/validations';
import { daysToSeconds } from '@/utils/date';

export default {
  layout: 'empty',
  components: {
    InputFields,
    Btn,
    SendEmailCode,
    ConfirmEmailCode,
  },
  async asyncData({ $axios, $cookies, route, store }) {
    const lang = $cookies.get('i18n_redirected');
    const new_code = route.query.authorization_code;
    const loginPage = await $axios.$get(`/api/v1/pages/lk-login?language=${lang}`);
    const loginPageFields = loginPage.fields;
    return { loginPageFields, new_code };
  },
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    passwordType: 'password',
    isShowSendEmailCode: false,
    isSendingEmailCode: false,
    isShowConfirmEmailCode: false,
    isConfirmingEmailCode: false,
    errorConfirmCode: false,
  }),
  computed: {
    ...mapState(['userLogged']),
  },
  methods: {
    ...mapActions(['setUserIsLogin']),
    changePasswordType() {
      this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
    },
    sendEmailCodePopupOpen() {
      if (!emailValidations(this.form.email)) {
        notificationService.error({ title: this.loginPageFields.incorrectEmail.text });
        return;
      }
      this.isShowSendEmailCode = true;
    },
    async onSignIn() {
      const trustedDeviceToken = this.getCookie('trusted_device');
      const data = new FormData();
      data.append('username', this.form.email);
      data.append('password', this.form.password);
      data.append('type', 'seller');

      const userVerified = await this.$axios
        .post('/api/v1/users/exists', data)
        .then((r) => true)
        .catch((e) => {
          notificationService.error({ title: e.response.data });
          return false;
        });

      if (userVerified) {
        if (trustedDeviceToken) {
          try {
            const result = await this.$axios.$post('/api/v1/jwt/create', data);
            this.isConfirmingEmailCode = false;
            this.isShowConfirmEmailCode = false;
            await this.login(result, trustedDeviceToken);
          } catch {
            notificationService.error({ title: this.loginPageFields.incorrectEmail.text });
          }
        } else {
          return this.sendEmailCodePopupOpen();
        }
      }
    },
    async sendEmailCode() {
      try {
        const data = new FormData();
        data.append('username', this.form.email);
        data.append('password', this.form.password);
        data.append('language', this.$i18n.locale);

        this.isSendingEmail = true;
        await this.$axios
          .$post('/api/v1/users/create-code', data, {
            _withoutAuthHeader: true,
          })
          .then((response) => {
            notificationService.success({
              text: `Your code is on its way`,
            });
            this.isSendingEmail = false;
            this.isShowSendEmailCode = false;
            this.isShowConfirmEmailCode = true;
          })
          .catch((error) => {
            this.isShowSendEmailCode = false;
            this.isShowConfirmEmailCode = false;
            notificationService.error({ title: error.response.data });
          });
      } catch {
        notificationService.error({ title: this.loginPageFields.incorrectEmail.text });
      }
    },
    resetErrorConfirmCode() {
      this.errorConfirmCode = false;
    },
    async verifyEmailCode(token, isTrustDevice) {
      try {
        this.isConfirmingEmailCode = true;

        const result = await this.$axios.$post('/api/v1/users/verify-code', { token }, { _withoutAuthHeader: true });
        this.isConfirmingEmailCode = false;

        this.isShowConfirmEmailCode = false;
        await this.login(result, isTrustDevice);
      } catch {
        this.isConfirmingEmailCode = false;
        this.errorConfirmCode = true;
      }
    },
    async login(token, isTrustDevice) {
      if (isTrustDevice) {
        this.$cookies.set('ulkid', token.access, { maxAge: daysToSeconds(30) });
        this.$cookies.set('ulkidRefresh', token.refresh, { maxAge: daysToSeconds(30) });
        this.$cookies.set('userlk', 'client', { maxAge: daysToSeconds(30) });
        this.$cookies.set('trusted_device', true, { maxAge: daysToSeconds(30) });
      } else {
        this.$cookies.set('ulkid', token.access);
        this.$cookies.set('ulkidRefresh', token.refresh);
        this.$cookies.set('userlk', 'client');
      }

      this.setUserIsLogin();
      window.location.href = this.localePath('/');
    },
    getCookie(name) {
      const cookieName = name + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.notification-view {
  top: 90% !important;
  left: 90% !important;
}

.login {
  min-height: 100%;

  &__content {
    position: absolute;
    height: 80%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__main-content {
    width: 600px;

    .logo {
      margin-bottom: 65px;
      width: 200px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .subtitle {
      &__text {
        width: 600px;
        font-size: 40px;
        line-height: 47px;
      }
    }
  }

  &__second-content {
    width: 460px;

    .input {
      margin-bottom: 20px;
      width: 100%;
    }

    .btn {
      margin-bottom: 12px;
      min-width: 100%;
    }

    .forgot-password {
      margin-bottom: 20px;
    }

    .password {
      position: relative;

      input {
        margin-bottom: 12px;
      }

      img {
        cursor: pointer;
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        top: 12px;
        z-index: 10;
      }
    }

    .forgot-password {
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: #959cad;
    }

    .sign-up {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 19px;
      color: rgba(30, 40, 67, 0.5);

      .link {
        margin-left: 8px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #1e2843;
      }
    }
  }
}
</style>
