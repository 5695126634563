var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "_container" }, [
        _c("div", { staticClass: "login__content" }, [
          _c("div", { staticClass: "login__main-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "subtitle" }, [
              _c("div", { staticClass: "subtitle__text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.loginPageFields.title.text) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "subtitle__text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.loginPageFields.title2.text) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "login__second-content",
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onSignIn.apply(null, arguments)
                },
              },
            },
            [
              _c("InputFields", {
                attrs: {
                  default: "",
                  placeholder: _vm.loginPageFields.inputPlaceholder1.text,
                },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "password" },
                [
                  _c("InputFields", {
                    attrs: {
                      default: "",
                      placeholder: _vm.loginPageFields.inputPlaceholder2.text,
                      type: _vm.passwordType,
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.passwordType == "text"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-show.svg"),
                          alt: "show-password",
                        },
                        on: { click: _vm.changePasswordType },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.passwordType == "password"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-hide.svg"),
                          alt: "show-password",
                        },
                        on: { click: _vm.changePasswordType },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "forgot-password",
                  attrs: { to: _vm.localePath(`/reset-password`), tag: "div" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginPageFields.forgotYourPassword.text) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sign-in", on: { click: _vm.onSignIn } },
                [
                  _c("Btn", {
                    attrs: { base: "", name: _vm.loginPageFields.btn.text },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sign-up" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.loginPageFields.lowerPage1.text) +
                      "\n          "
                  ),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "link",
                      attrs: {
                        id: "LSingUpLink",
                        to: _vm.localePath(`/registration`),
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.loginPageFields.lowerPage2.text) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("SendEmailCode", {
        attrs: {
          email: _vm.form.email,
          loading: _vm.isSendingEmailCode,
          translation: _vm.loginPageFields,
        },
        on: { sendEmailCode: _vm.sendEmailCode },
        model: {
          value: _vm.isShowSendEmailCode,
          callback: function ($$v) {
            _vm.isShowSendEmailCode = $$v
          },
          expression: "isShowSendEmailCode",
        },
      }),
      _vm._v(" "),
      _c("ConfirmEmailCode", {
        attrs: {
          loading: _vm.isConfirmingEmailCode,
          "error-confirm-code": _vm.errorConfirmCode,
          translation: _vm.loginPageFields,
        },
        on: {
          sendEmailCode: _vm.sendEmailCode,
          confirmEmailCode: _vm.verifyEmailCode,
          resetErrorConfirmCode: _vm.resetErrorConfirmCode,
        },
        model: {
          value: _vm.isShowConfirmEmailCode,
          callback: function ($$v) {
            _vm.isShowConfirmEmailCode = $$v
          },
          expression: "isShowConfirmEmailCode",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/header/logo.svg"), alt: "logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }